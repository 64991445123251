import React from "react";

interface Props {
  children?: React.ReactNode;
}
const AppMainContainer = ({
  children,
}: Props & React.HTMLAttributes<HTMLDivElement>) => {
  return <main className="container">{children}</main>;
};

export default AppMainContainer;
