import React from 'react';
import { FaHome } from "react-icons/fa";

const NotFound:React.FC = () => {
  const routes = [
    { pathName: "/", name: "Main", icon: FaHome },
    // { pathName: "/users", name: "Users", icon: FaUsers },
  ];

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h6 className="text-6xl">404</h6>
      <span className="text-2xl font-thin mt-3 mb-5">
        Oops! We don't have the page you're looking for.
      </span>
      <ul className="flex">
        {routes.map((route) => {
          return (
            <li key={route.pathName}>
              <a href={route.pathName}>
                <button className="flex items-center border-[1px] border-white py-1 px-3 mx-2 hover:bg-emerald-300 hover:text-slate-900 hover:border-emerald-300">
                  <route.icon className="w-4 h-4 mr-2"></route.icon>
                  <span className="text-lg font-thin">{route.name}</span>
                </button>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default NotFound;