import React from "react";
import { PiMathOperations } from "react-icons/pi";

interface FooterProps {
  pathname: string;
}

const Footer: React.FC<FooterProps> = ({ pathname }) => {
  // const location = useLocation();
  return (
    <footer className="mt-5 bg-black">
      <div className="container p-10">
        <div className="flex items-center">
          {/* <img
            className="h-8 w-auto"
            src="https://storage.googleapis.com/smart-word-counter-bucket/logo.jpg"
            alt="smart word counter"
          /> */}
          <PiMathOperations className="text-2xl text-white" />
          <p className="font-2xl ml-2 text-white">Calculazor</p>
        </div>
        <div className="flex flex-col">
          {[
            { name: "Home", href: "/" },
            { name: "About", href: "/about" },
          ].map((route) => {
            return (
              <a
                href={route.href}
                key={route.name}
                className={`mt-5 text-lg font-extralight text-zinc-400 ${
                  pathname === route.href ? "!text-white" : "hover:text-white"
                } transition duration-300`}
              >
                {route.name}
              </a>
            );
          })}
        </div>
      </div>
      <p className="w-full bg-zinc-400 py-2 text-center text-sm text-black md:text-base">
        &copy; 2024 TrustOne Technologies. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
