import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import BMICalculator from "./pages/BMICalculator/BMICalculator";
// import Login from "./pages/Login";
// import ProtectedRoute from "./components/ProtectedRoute";
import AppContainer from "./AppContainer";

interface AppProps {
  pathname: string;
}

const App: React.FC<AppProps> = ({ pathname }) => {
  return (
    <Routes>
      <Route path="/" element={<AppContainer pathname={pathname} />}>
        <Route index element={<HomePage />} />
        <Route path="about" element={<About />} />
        <Route path="bmicalculator" element={<BMICalculator />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* 
      You can uncomment and add these routes if needed:
      <Route path="/admin" element={<ProtectedRoute />}>
        <Route path="login" element={<Login />} />
      </Route>
      */}
    </Routes>
  );
};

export default App;
