import React, { useState } from "react";
import AmericanFlag from "./AmericanFlag";
import { PiMathOperations } from "react-icons/pi";

const menu = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
];

interface NavbarProps {
  pathname: string;
}

const Navbar: React.FC<NavbarProps> = ({ pathname }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? "hidden" : "auto";
  };

  return (
    <nav className="bg-black">
      <div className="container">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="relative items-center justify-center"
              onClick={handleToggle}
            >
              {!isOpen && (
                <svg
                  className="hamburger h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              )}

              {isOpen && (
                <svg
                  className="exit-sign h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <a href="/" className="flex flex-shrink-0 items-center">
              {/* <img
                className="h-8 w-auto"
                src="https://storage.googleapis.com/smart-word-counter-bucket/logo.jpg"
                alt="smart word counter"
              /> */}
              <PiMathOperations className="text-2xl text-white" />
              <p className="ml-2 text-lg font-normal text-white">Calculazor</p>
            </a>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex">
                {menu.map((route) => {
                  return (
                    <a
                      href={route.href}
                      key={route.name}
                      className={`mx-3 text-lg font-extralight text-zinc-400 ${
                        pathname === route.href
                          ? "!text-white"
                          : "hover:text-white"
                      } transition duration-300`}
                    >
                      {route.name}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6">
            <div className="relative ml-3">
              <div className="ml-auto flex items-center space-x-1 md:order-2 md:space-x-0 rtl:space-x-reverse">
                <AmericanFlag />

                <p className="text-gray-900 hidden items-center justify-center rounded-lg text-sm font-medium text-white sm:inline-flex">
                  English (US)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          className={`absolute z-10 h-full w-full bg-black sm:hidden ${
            isOpen
              ? "visible translate-y-0 opacity-100"
              : "invisible -translate-y-60 opacity-0"
          }`}
        >
          <div className="space-y-1 px-2 pb-3 pt-2">
            {menu.map((route) => {
              return (
                <a
                  onClick={handleToggle}
                  href={route.href}
                  key={route.name}
                  className={`!my-4 block px-3 py-1 text-base font-medium text-white md:text-zinc-400 ${
                    pathname === route.href
                      ? "bg-orange !text-black"
                      : "hover:border-black hover:bg-orange hover:text-black"
                  } transition duration-300`}
                >
                  {route.name}
                </a>
              );
            })}
          </div>

          {/* <div className="border-b-1 border-zinc-400"></div> */}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
