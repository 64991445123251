import React from 'react';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AppMainContainer from "./AppMainContainer";
import { Outlet } from "react-router-dom";

interface ApplicationContainerProps {
  pathname: string;
}

const ApplicationContainer: React.FC<ApplicationContainerProps> = ({pathname}) => {
  return (
    <>
      <Navbar pathname={pathname}/>
      <AppMainContainer>
        <Outlet />
      </AppMainContainer>
      <Footer pathname={pathname}/>
    </>
  );
};

export default ApplicationContainer;
