import React from "react";

const HomePage: React.FC = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <h1 className="text-3xl font-bold">Welcome to the Calculator Website!</h1>
      <p className="mt-4 text-lg">
        This website offers various types of calculators to help you with your
        calculations. Click on the links above to explore.
      </p>
    </div>
  );
};

export default HomePage;
