import React from "react";

const About: React.FC = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <div>
        <h1 className="mb-4 text-3xl font-bold">
          About This Calculator Website
        </h1>
        <p className="text-lg">
          This website provides a variety of calculators to assist you with
          different calculations, including BMI calculation, financial
          calculations, and more. Our goal is to offer easy-to-use tools for
          accurate and quick results.
        </p>
      </div>
    </div>
  );
};

export default About;
