import React, { useState } from "react";

const App: React.FC = () => {
  const [weight, setWeight] = useState<number | "">("");
  const [heightCm, setHeightCm] = useState<number | "">("");
  const [heightFeet, setHeightFeet] = useState<number | "">("");
  const [heightInches, setHeightInches] = useState<number | "">("");
  const [bmi, setBmi] = useState<number | null>(null);
  const [isMetric, setIsMetric] = useState<boolean>(true);

  const calculateBMI = () => {
    let heightInMeters: number;

    if (isMetric && heightCm) {
      heightInMeters = heightCm / 100;
    } else if (!isMetric && heightFeet && heightInches !== "") {
      heightInMeters =
        (Number(heightFeet) * 12 + Number(heightInches)) * 0.0254;
    } else {
      return;
    }

    const weightInKg = isMetric ? Number(weight) : Number(weight) * 0.453592;
    const calculatedBmi = weightInKg / (heightInMeters * heightInMeters);
    setBmi(parseFloat(calculatedBmi.toFixed(2)));
  };

  const toggleUnitSystem = () => {
    setIsMetric(!isMetric);
    setWeight("");
    setHeightCm("");
    setHeightFeet("");
    setHeightInches("");
    setBmi(null);
  };

  const getClassification = (bmi: number) => {
    if (bmi < 16) return "Severe Thinness";
    if (bmi >= 16 && bmi < 17) return "Moderate Thinness";
    if (bmi >= 17 && bmi < 18.5) return "Mild Thinness";
    if (bmi >= 18.5 && bmi < 25) return "Normal";
    if (bmi >= 25 && bmi < 30) return "Overweight";
    if (bmi >= 30 && bmi < 35) return "Obese Class I";
    if (bmi >= 35 && bmi < 40) return "Obese Class II";
    return "Obese Class III";
  };

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="w-full max-w-4xl rounded-none bg-white">
          <div className="flex flex-col items-center">
            <div className="mt-10 w-full md:w-1/2">
              <h1 className="mb-10 text-2xl font-bold">BMI Calculator</h1>
              <div className="mb-4 flex items-center justify-between">
                <label className="block text-sm font-medium">Unit System</label>
                <button
                  onClick={toggleUnitSystem}
                  className="hover:bg-gray-800 bg-black p-2 py-2 text-white transition-colors hover:text-white focus:outline-none"
                >
                  {isMetric ? "Switch to Standard" : "Switch to Metric"}
                </button>
              </div>
              <div className="mb-4">
                <label className="mb-2 block text-sm font-medium">
                  Weight ({isMetric ? "kg" : "lbs"})
                </label>
                <input
                  type="number"
                  value={Number(weight) <= 0 ? "" : weight}
                  onChange={(e) => setWeight(Number(e.target.value))}
                  className="w-full border p-2 focus:outline-none"
                  placeholder={`Enter weight in ${isMetric ? "kg" : "lbs"}`}
                />
              </div>
              {isMetric ? (
                <div className="mb-4">
                  <label className="mb-2 block text-sm font-medium">
                    Height (cm)
                  </label>
                  <input
                    type="number"
                    value={Number(heightCm) <= 0 ? "" : heightCm}
                    onChange={(e) => setHeightCm(Number(e.target.value))}
                    className="w-full border p-2 focus:outline-none"
                    placeholder="Enter height in cm"
                  />
                </div>
              ) : (
                <div className="mb-4 flex space-x-2">
                  <div>
                    <label className="mb-2 block text-sm font-medium">
                      Height (feet)
                    </label>
                    <input
                      type="number"
                      value={Number(heightFeet) <= 0 ? "" : heightFeet}
                      onChange={(e) =>
                        setHeightFeet(
                          e.target.value ? Number(e.target.value) : "",
                        )
                      }
                      className="w-full border p-2 focus:outline-none"
                      placeholder="Feet"
                    />
                  </div>
                  <div>
                    <label className="mb-2 block text-sm font-medium">
                      Height (inches)
                    </label>
                    <input
                      type="number"
                      value={Number(heightInches) <= 0 ? "" : heightInches}
                      onChange={(e) =>
                        setHeightInches(
                          e.target.value ? Number(e.target.value) : "",
                        )
                      }
                      className="w-full border p-2 focus:outline-none"
                      placeholder="Inches"
                    />
                  </div>
                </div>
              )}
              <button
                onClick={calculateBMI}
                className="hover:bg-gray-800 w-full bg-black py-2 text-white transition-colors hover:text-white focus:outline-none"
              >
                Calculate
              </button>
              {/* {bmi && ( */}
              <div className="mt-5 min-h-20 text-center">
                {bmi && (
                  <>
                    <p>
                      Your BMI is: <strong>{bmi}</strong>
                    </p>
                    <p className="text-lg font-semibold">
                      {getClassification(bmi)}
                    </p>
                  </>
                )}
              </div>
              {/* )} */}
            </div>
            <div className="mb-10 w-full md:w-1/2">
              <div className="mb-5 flex justify-between">
                <h2 className="mb-2 text-lg font-bold">BMI Classification</h2>
                {/* <button
                  onClick={toggleDarkMode}
                  className="bg-gray-200 dark:bg-gray-600 rounded-full p-2 focus:outline-none"
                >
                  {isDarkMode ? "🌞" : "🌜"}
                </button> */}
              </div>
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th className="border-b p-2 text-left">Classification</th>
                    <th className="border-b p-2 text-left">
                      BMI range (kg/m²)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b p-2">Severe Thinness</td>
                    <td className="border-b p-2">&lt; 16</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">Moderate Thinness</td>
                    <td className="border-b p-2">16 - 17</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">Mild Thinness</td>
                    <td className="border-b p-2">17 - 18.5</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">Normal</td>
                    <td className="border-b p-2">18.5 - 25</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">Overweight</td>
                    <td className="border-b p-2">25 - 30</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">Obese Class I</td>
                    <td className="border-b p-2">30 - 35</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">Obese Class II</td>
                    <td className="border-b p-2">35 - 40</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">Obese Class III</td>
                    <td className="border-b p-2">&gt; 40</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
